import type {
  CampaignState,
  TweetOrigin,
  TweetRewardState,
} from "evangelist-common/enums";
import type { CreatorappCampaignWeekUserEligibilityState } from "evangelist-db-schema/api";
import type { AdvertiserDto, MonetaryDto } from "~~/_api_generated_";

export interface CampaignListItem {
  campaignWeekId: string;
  campaignUuid: string | null;
  title: string;
  descriptionHtml: string;
  budgetCurrency: string;
  totalBudget: MonetaryDto;
  advertiser: Omit<AdvertiserDto, "isPreferred">;
  eligibilityState: CreatorappCampaignWeekUserEligibilityState;
  campaignState: CampaignState;
  apyBonus: number;
  signupBonus: number;
  walletAddress: string;
  participated: boolean;
  dateStart: string;
  dateEnd: string;
  dateMaturity: string;
  userTopN: number;
}

export interface CampaignTip {
  type: "plus" | "minus";
  content: string;
}

export interface TweetListItem {
  id: string;
  state: TweetRewardState;
  datePosted: string;
  dateExpires: string | null;
  content: string;
  origin: TweetOrigin;
  previewImageUrls: string[];
}

export interface TweetListItemWithCampaignInfo extends TweetListItem {
  campaignTitle: string | null;
  advertiserTheme: string | null;
  campaignUserTopN: number | null;
  campaignCount: number;
}

/**
 * Originally from creator-backend api
 *
 * @export
 * @interface SuggestedCampaignDto
 */
export interface SuggestedCampaignDto {
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  descriptionHtml: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  budgetCurrency: string;
  /**
   *
   * @type {MonetaryDto}
   * @memberof SuggestedCampaignDto
   */
  totalBudget: MonetaryDto;
  /**
   *
   * @type {number}
   * @memberof SuggestedCampaignDto
   */
  apyBonus: number;
  /**
   *
   * @type {number}
   * @memberof SuggestedCampaignDto
   */
  signupBonus: number;
  /**
   *
   * @type {AdvertiserDto}
   * @memberof SuggestedCampaignDto
   */
  advertiser: AdvertiserDto;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  walletAddress: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  state: SuggestedCampaignDtoStateEnum;
  /**
   *
   * @type {boolean}
   * @memberof SuggestedCampaignDto
   */
  participated: boolean;
  /**
   *
   * @type {CampaignEligibilityDto}
   * @memberof SuggestedCampaignDto
   */
  eligibility: CampaignEligibilityDto;
  /**
   *
   * @type {Array<CampaignBonusDto>}
   * @memberof SuggestedCampaignDto
   */
  bonuses: Array<CampaignBonusDto>;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  dateStart: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  dateEnd: string;
  /**
   *
   * @type {string}
   * @memberof SuggestedCampaignDto
   */
  dateMaturity: string;

  userTopN: number;
}

export const SuggestedCampaignDtoStateEnum = {
  Inactive: "inactive",
  Active: "active",
  Ending: "ending",
  Evaluating: "evaluating",
  Evaluated: "evaluated",
} as const;

export type SuggestedCampaignDtoStateEnum =
  (typeof SuggestedCampaignDtoStateEnum)[keyof typeof SuggestedCampaignDtoStateEnum];

/**
 *
 * @export
 * @interface CampaignEligibilityDto
 */
interface CampaignEligibilityDto {
  /**
   *
   * @type {string}
   * @memberof CampaignEligibilityDto
   */
  state: CampaignEligibilityDtoStateEnum;
  /**
   *
   * @type {Array<CampaignRestrictionDto>}
   * @memberof CampaignEligibilityDto
   */
  restrictions: Array<CampaignRestrictionDto>;
  /**
   *
   * @type {string}
   * @memberof CampaignEligibilityDto
   */
  additionalCampaignCheckType: CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CampaignEligibilityDto
   */
  lastNonEligibleWalletCaipAddress: string;
}

export const CampaignEligibilityDtoStateEnum = {
  New: "New",
  EvaluationPending: "EvaluationPending",
  Evaluating: "Evaluating",
  NotInvited: "NotInvited",
  NotEligibleBasic: "NotEligibleBasic",
  EligibleBasic: "EligibleBasic",
  Eligible: "Eligible",
  NotEligible: "NotEligible",
} as const;

export type CampaignEligibilityDtoStateEnum =
  (typeof CampaignEligibilityDtoStateEnum)[keyof typeof CampaignEligibilityDtoStateEnum];

const CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum = {
  None: "none",
  InvitationOnly: "invitation-only",
  ExternalCheck: "external-check",
  ExternalWalletCheck: "external-wallet-check",
} as const;

type CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum =
  (typeof CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum)[keyof typeof CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum];

/**
 *
 * @export
 * @interface CampaignRestrictionDto
 */
interface CampaignRestrictionDto {
  /**
   *
   * @type {string}
   * @memberof CampaignRestrictionDto
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CampaignRestrictionDto
   */
  contentHtml: string;
}

/**
 *
 * @export
 * @interface CampaignBonusDto
 */
export interface CampaignBonusDto {
  /**
   *
   * @type {string}
   * @memberof CampaignBonusDto
   */
  dedupeId: string;
  /**
   *
   * @type {MonetaryDto}
   * @memberof CampaignBonusDto
   */
  amount: MonetaryDto;
  /**
   *
   * @type {string}
   * @memberof CampaignBonusDto
   */
  state: string;
}
